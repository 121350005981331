<template>
  <div class="row">
    <a
      v-for="collaborator in collaborators"
      :key="collaborator.id"
      title="Selecteer medewerker"
      class="col-sm-2 user-friends tw-my-2 tw-text-center"
      @click="selectedCollaborator = collaborator.id"
    >
      <img
        :src="getPicture(collaborator)"
        :class="['img-circle tw-inline-block', { 'active': selectedCollaborator === collaborator.id }]"
      />
      <br />
      {{ collaborator.first_name }}
    </a>
    <a
      title="Selecteer medewerker"
      class="col-sm-2 user-friends tw-my-2 tw-text-center"
      @click="selectedCollaborator = currentCollaboratorId"
    >
      <img
        :src="getPicture(currentCollaborator)"
        :class="['img-circle tw-inline-block', { 'active': selectedCollaborator === currentCollaboratorId }]"
      />
      <br />
      Mezelf
    </a>
  </div>
</template>

<script>
/**
 * This component takes a property or a list of collaborators and allows the user to select a number of collaborators.
 * If a property is given the collaborators linked to this property are loaded and shown, otherwise the list of
 * collaborator is shown.
 */
import { mapGetters, mapActions } from 'vuex'
import ProfileImg from '@/assets/img/profile.png'

export default {
  name: 'CollaboratorSelector',
  props: {
    forProperty: {
      type: Object
    },
    forProject: {
      type: Object
    },
    forCollaborators: {
      type: Array
    }
  },
  data () {
    return {
      selectedCollaborator: null
    }
  },
  watch: {
    forProperty: {
      immediate: true,
      handler (value) {
        if (value) {
          this.loadPropertyCollaborators(value.id)
        }
      }
    },
    forProject: {
      immediate: true,
      handler (value) {
        if (value) {
          this.loadProjectCollaborators(value.id)
        }
      }
    },
    collaborators: {
      immediate: true,
      handler (collaborators) {
        const primaryCollaborator = collaborators?.find(collaborator => collaborator.primary)
        if (this.forCollaborators?.length) this.selectedCollaborator = this.currentCollaboratorId
        else this.selectedCollaborator = primaryCollaborator?.id || this.currentCollaboratorId
      }
    }
  },
  computed: {
    ...mapGetters('properties', ['getPropertyCollaborators', 'getProjectCollaborators']),
    ...mapGetters({ currentCollaborator: 'account/collaborator' }),

    currentCollaboratorId () {
      return this.currentCollaborator?.id
    },
    collaborators () {
      if (this.forProperty) {
        return this.getPropertyCollaborators(this.forProperty.id)
      } else if (this.forProject) {
        return this.getProjectCollaborators(this.forProject.id)
      } else if (this.forCollaborators?.length) {
        return this.forCollaborators
      }
      return []
    }
  },
  methods: {
    ...mapActions('properties', ['loadPropertyCollaborators', 'loadProjectCollaborators']),

    getPicture (collaborator) {
      return collaborator?.picture || ProfileImg
    }
  }
}
</script>

<style scoped>
.active {
  border: 3px solid #1c84c6;
}
</style>
