<template>
  <span style="text-align: center" v-if="inline">
    <img src="@/assets/img/loader.gif" style="height:20px" /> {{ message }}<br>
  </span>
  <div style="text-align: center" v-else>
    <img src="@/assets/img/loader.gif" style="height:100px" /><br>
    <small>{{ message }}</small>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    inline: {
      default: false
    },
    message: {
      default: 'Gegevens worden geladen...'
    }
  }
}
</script>

<style scoped>
img {
  display: inline;
}
</style>
