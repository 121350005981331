<template>
  <Modal ref="modal" size="md" title="Snelle lead">
    <span slot="header">Via dit scherm kan je ofwel snel een lead aanmaken of op een bestaande lead een activiteit registreren</span>
    <form class="form-horizontal">
      <div class="alert alert-warning" v-if="existingLead">
        Er bestaat al een lead voor dit contact. Er zal een activiteit worden ingepland op deze lead.
        <router-link :to="{ name: 'LeadDetails', params: { id: existingLead.id }}" target="_blank">
          Details
        </router-link>
      </div>
      <div class="row">
        <div class="col-md-6" v-if="project || property">
          <ContactPicker
            ref="contactPicker"
            v-model="selectedContact"
            @deselect="existingLead = null"
            @select="existenceCheck"
          />
        </div>
        <div class="col-md-6" v-if="contact">
          <PropertyPicker
            ref="propertyPicker"
            v-model="selectedProperty"
            @deselect="existingLead = null"
            @select="existenceCheck"
          />
        </div>

        <div class="col-md-6">
          <select class="form-control" ref="activityTypeSelect" v-model="selectedActivity">
            <option :key="activityType.id" :value="activityType.id" v-for="activityType in activityTypes">
              <span>{{ activityType.name }}</span>
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" style="padding-top: 15px;">
          <DateRangePicker ref="dateRangePicker" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12" style="padding-top: 15px;">
          <div class="form-group">
            <label class="col-sm-2 control-label">Feedback<br>rapportering</label>
            <div class="col-sm-10">
              <textarea
                v-model="feedbackReporting"
                name="reportingFeedback"
                rows="5"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2 control-label">Interne<br>feedback</label>
            <div class="col-sm-10">
              <textarea
                v-model="feedbackInternal"
                name="internalFeedback"
                rows="5"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2 control-label">Lead aanmaken voor</label>
            <div class="col-sm-10">
              <CollaboratorSelector
                ref="collaboratorSelector"
                :forProperty="property || selectedProperty"
                :forProject="project"
              />
            </div>
          </div>
        </div>
      </div>

      <hr>
      <div :key="createdLead.id" class="col-md-12 no-padding" v-for="createdLead in createdLeads">
        <div class="col-md-12">
          <a @click="goToLead(createdLead.id)">
            {{ createdLead.entity_reference }} {{ createdLead.name }}
          </a>
        </div>
      </div>
    </form>
    <div slot="footer">
      <button @click="hide" class="btn btn-success" data-dismiss="modal" type="button">
        <i class="fa fa-times-circle" /> Sluiten
      </button>
      <button @click="saveLeadHandler(STATUS_FOLLOW)" class="btn btn-primary" type="button">
        <i class="fa fa-check" /> Opslaan en opvolgen
      </button>
      <button @click="saveLeadHandler(STATUS_BREAK)" class="btn btn-danger" type="button">
        <i class="fa fa-times" /> Opslaan en break
      </button>
    </div>
  </Modal>
</template>

<script>
import moment from 'moment'

import Modal from '@/components/iam/Modal'
import DateRangePicker from '@/components/iam/DateRangePicker'

import PropertyPicker from '@/components/properties/PropertyPicker'
import ContactPicker from '@/components/contacts/ContactPicker'
import CollaboratorSelector from '@/components/organization/CollaboratorSelector'

import { mapActions } from 'vuex'
import { errorModal, successModal } from '@/modalMessages'
import { getSalesActivityTypes, createActivity } from '@/services/sales'
import { breakActivity } from '@/utils/sales'

export default {
  name: 'QuickLeadModal',
  components: {
    CollaboratorSelector,
    ContactPicker,
    Modal,
    DateRangePicker,
    PropertyPicker
  },
  props: {
    contact: {
      default: null
    },
    property: {
      default: null
    },
    project: {
      default: null
    }
  },
  constants: {
    STATUS_FOLLOW: 0,
    STATUS_BREAK: 2
  },
  data () {
    return {
      selectedActivity: 2,
      createdLeads: [],
      selectedProperty: null,
      selectedContact: null,
      lead: {},
      existingLead: null,
      activityTypes: [],
      feedbackReporting: '',
      feedbackInternal: ''
    }
  },
  /**
   * When the component is created, we want to load the different activity types the user can select from.
   * TODO: Load this upon application initialization and save this in the store.
   */
  created () {
    this.init()
  },
  computed: {
    startDate () {
      return this.$refs.dateRangePicker.getStartDate()
    },
    endDate () {
      return this.$refs.dateRangePicker.getEndDate()
    }
  },
  methods: {
    ...mapActions('sales', [
      'createLead',
      'checkExistingLead'
    ]),

    async init () {
      const response = await getSalesActivityTypes()
      this.activityTypes = response?.data?.results
    },
    /**
     * Show the QuickLeadModal
     * @param defaultActivity: If given, this activity will be selected in the UI
     */
    show (defaultActivity) {
      if (defaultActivity) this.selectedActivity = defaultActivity
      this.$refs.modal.show()
      this.$refs.dateRangePicker.setStartDate(moment().startOf('hour'))
      this.$refs.dateRangePicker.setEndDate(moment().startOf('hour').add(30, 'minutes'))
    },
    /**
     * Hide the QuickLeadModal
     */
    hide () {
      this.$refs.modal.hide()
    },
    /**
     * Clear the form and reset all the data that was calculated.
     * This method doesn't reset the properties passed into this component.
     */
    clearForm () {
      if (this.project || this.property) {
        this.$refs.contactPicker.deselect()
      } else {
        this.$refs.propertyPicker.deselect()
      }
      this.selectedContact = null
      this.selectedProperty = null
      this.$refs.activityTypeSelect.value = 2
      this.feedbackInternal = ''
      this.feedbackReporting = ''
      this.existingLead = null
      this.lead = {}
      this.date = null
    },
    /**
     * Check for an existing lead for the select property / project / contact combination.
     * @param value: The ID of the selected property or contact.
     */
    async existenceCheck (value) {
      try {
        let data = {}

        if (this.project) {
          // In the event that we are creating a lead via the project page and selecting a contact.
          data = {
            contact_id: value.id,
            project_id: this.project.id
          }
        } else if (this.property) {
          // In the event that we are creating a lead via the property page and selecting a contact.
          data = {
            contact_id: value.id,
            property_id: this.property.id
          }
        } else {
          // In the event that we are creating a lead via the contact page and selecting a property.
          data = {
            contact_id: this.contact.id,
            property_id: value.id
          }
        }

        const existingLead = await this.checkExistingLead(data)
        if (existingLead) this.existingLead = existingLead
        return existingLead
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * Navigate to the given lead
     */
    goToLead (leadId) {
      this.hide()
      this.$router.push({ name: 'LeadDetails', params: { id: leadId } })
    },
    /**
     * Click handler for when the user clicks the "Create lead" button. The status given indicates what button
     * the user has clicked (STATUS_FOLLOW or STATUS_BREAK).
     * This function validates the form input and calls the correct methods that handle the business logic.
     * @param status
     * @returns {Promise<void>}
     */
    async saveLeadHandler (status) {
      try {
        if (this.project || this.property) {
          if (!this.selectedContact) return errorModal('Je hebt geen contact geselecteerd')
        } else if (!this.selectedProperty) {
          return errorModal('Je hebt geen pand geselecteerd')
        }
        const lead = await this.saveLead()
        const response = await this.saveActivity(lead, status)
        this.clearForm()
        let successMessage = 'De lead is aangemaakt'
        if (status === this.STATUS_BREAK) successMessage = 'De lead is aangemaakt en het breaken is succesvol doorgevoerd'
        successModal(successMessage)
        return response
      } catch (error) {
        errorModal('Er ging iets verkeerd bij het aanmaken van de lead, gelieve opnieuw te proberen')
      }
    },

    /**
     * Save the lead
     * @returns {Promise<void>}
     */
    async saveLead () {
      const data = {
        candidates: [this.selectedContact?.id || this.contact?.id],
        agent: this.$refs.collaboratorSelector.selectedCollaborator
      }

      if (this.project) data.project = this.project.id
      else if (this.property) data.property = this.property.id
      else if (this.selectedProperty) data.property = this.selectedProperty.id

      let lead = {}
      if (this.existingLead) lead = this.existingLead // We use the existing lead as the lead to create the activity on.
      else lead = await this.createLead(data)
      this.lead = lead
      this.createdLeads.push(lead)
      this.$emit('lead-saved')
      return lead
    },
    async saveActivity (lead, status) {
      const payload = {
        type: this.selectedActivity,
        finished: true,
        status: 4, // finished
        start_date: this.startDate,
        end_data: this.endDate,
        feedback_reporting: this.feedbackReporting,
        description: this.feedbackInternal
      }
      const activity = await createActivity(lead.id, payload)

      if (status === this.STATUS_BREAK) {
        const data = {
          leadId: lead.id,
          activityId: activity?.data?.id,
          feedbackInternal: this.feedbackInternal,
          feedbackReporting: this.feedbackReporting
        }
        return await breakActivity(data)
      }
      return activity
    }
  }
}
</script>
