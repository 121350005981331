<template>
  <Modal ref="modal" title="Kandidaten voorstellen aan eigenaar" size="xl">
    <div>
      <div class="row">
        <div class="col-sm-6">
          <p>Via dit scherm kun je de kandidaatfiches aanmaken om voor te stellen aan de eigenaar.</p>
          <p>IAM zal voor onderstaande kandidaten de fiche opmaken en doormailen aan de eigenaar. Indien gewenst kan je
            rechts de inhoud van de e-mail aan de eigenaar aanpassen. De e-mail die naar de eigenaar verstuurd wordt
            zal ook naar jou gestuurd worden ter bevestiging.</p>
          <DataGrid
            :data="leadsWithRenterProfile"
            :columns="columns"
            :actions="actions"
            :pageSize="10"
            @example="getCandidateReport"
          />
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <div class="col-sm-12">
              <input
                v-model="subject"
                type="text"
                placeholder="Het onderwerp van je e-mail"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-12">
              <Editor ref="emailEditor" v-model="email_body" :placeholder="'Inhoud e-mail'" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer">
      <button
        :class="[
          'btn btn-secondary',
          { 'btn-primary': leadsWithRenterProfile.length }
        ]"
        @click="sendReports"
      >
        Aanmaken & verzenden
      </button>
      <a class="btn btn-white" @click="hide">Sluiten</a>
    </span>
  </Modal>
</template>

<script>
import Modal from '@/components/iam/Modal'
import Editor from '@/components/iam/Editor'
import DataGrid from '@/components/iam/DataGrid'

import { mapActions, mapGetters } from 'vuex'
import { startLoadingModal, successModal, errorModal } from '@/modalMessages'
import { getMatchesForIds } from '@/services/sales'

export default {
  name: 'OwnerPresentationModal',
  components: { Modal, Editor, DataGrid },
  props: {
    leadIds: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      subject: 'Voorstelling kandidaat-huurders',
      email_body: '',
      leadsWithRenterProfile: [],
      columns: [
        {
          key: 'candidate_name',
          title: 'Kandidaat'
        }
      ],
      actions: [
        {
          key: 'example',
          title: 'Voorbeeld',
          type: 'default',
          icon: 'eye'
        }
      ],
      leadObjects: []
    }
  },
  computed: {
    ...mapGetters('sales', ['getRenterProfileForLead'])
  },
  methods: {
    ...mapActions('sales', ['loadRenterProfileForLead', 'sendRenterProfileReports']),

    async show () {
      this.$refs.modal.show()
      const renterProfiles = []
      const leadIdsWithRenterProfile = []
      const response = await getMatchesForIds({ ids: this.leadIds })
      this.leadObjects = response?.data?.results
      for (const leadId of this.leadIds) {
        const renterProfile = await this.loadRenterProfileForLead({ id: leadId })
        if (renterProfile) {
          leadIdsWithRenterProfile.push(leadId)
        }
        renterProfiles.push(renterProfile)
      }
      this.leadsWithRenterProfile = this.leadObjects.filter(
        leadObject => {
          return leadIdsWithRenterProfile.includes(leadObject.id)
        })
    },
    hide () {
      this.$refs.modal.hide()
    },
    getCandidateReport (lead) {
      const renterProfile = this.getRenterProfileForLead(lead.id)
      window.open('/api/sales/renterprofile/' + renterProfile.id + '/report', '_blank')
    },
    async sendReports () {
      if (this.leadsWithRenterProfile.length) {
        startLoadingModal('Je e-mail wordt verstuurd...')
        const leads = this.leadsWithRenterProfile.map(lead => lead.id)
        await this.sendRenterProfileReports({
          subject: this.subject,
          contents: this.email_body,
          leads: leads
        }).catch(() => {
          errorModal('Er ging iets mis bij het versturen van deze e-mail')
        })
        successModal('De e-mail werd succesvol verstuurd.')
        this.hide()
      }
    }
  }
}
</script>
