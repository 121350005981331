<template>
  <BaseModal ref="modal" title="Sms'en versturen ter bevestiging van bezoek" max-width="tw-max-w-4xl">
    <FormulateForm
      name="sendBulkSms"
      v-model="values"
     @submit="send"
    >
      <p class="tw-italic tw-mb-8">
        Hieronder vind je een overzicht van alle geselecteerde leads waarvoor een bezoekactiviteit in de toekomst is ingepland.<br>
        De inhoud van het bericht kan nog worden aangepast. De waardes tussen de £-tekens worden bij het versturen vervangen door de juiste gegevens per kandidaat.
      </p>

      <h2>Kandidaten</h2>
      <table class="tw-w-full tw-mt-4 tw-mb-8 tw-table-fixed tw-border-0.5 tw-border-collapse">
        <thead>
          <tr>
            <td class="tw-font-semibold">Lead</td>
            <td class="tw-font-semibold">Datum en tijdstip bezoek</td>
            <td class="tw-font-semibold">Taal</td>
            <td class="tw-font-semibold">Gsm-nummer</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(lead, index) in leads" :key="index">
            <td>{{ lead.candidate.display_name }}</td>
            <td>{{ formatDateForLocale(lead.activity.start_date) }} {{ formatHHmm(lead.activity.start_date) }}</td>
            <td>{{ USER_LANG_OPTIONS[lead.candidate.language] }}</td>
            <td>{{ lead.candidate.mobile }}</td>
          </tr>
        </tbody>
      </table>

      <h2 class="tw-mt-8">Sms-bericht</h2>
      <FormulateInput
        type="translatable"
        name="template"
      />

      <FormulateInput v-show="!isSent" type="submit" :disabled="isLoading">
        <i
          :class="[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-sms'
          ]"
        />
        Verzenden
      </FormulateInput>

      <p v-show="isSent" class="tw-text-green-500 tw-font-bold">De sms'en werden succesvol verstuurd naar {{ leads.length }} {{ leads.length === 1 ? 'kandidaat' : 'kandidaten' }}.</p>

    </FormulateForm>
  </BaseModal>
</template>

<script>

import { getSmsData, sendBulkSms } from '@/services/sales'
import { formatDateForLocale, formatHHmm, USER_LANG_OPTIONS } from '@/utils/helpers'
import { errorModal } from '@/modalMessages'

export default {
  name: 'SMSBulkModal',
  props: {
    leadIds: {
      type: Array,
      default: () => []
    }
  },
  constants: {
    USER_LANG_OPTIONS
  },
  computed: {
    headers () {
      return [
        { value: 'id', text: 'Naam' },
        { value: 'email', text: 'E-mail' },
        { value: 'mobile', text: 'Gsm-nummer' },
        { value: 'phone', text: 'Telefoonnummer' }
      ]
    }
  },
  data () {
    return {
      values: {},
      leads: [],
      isLoading: false,
      isSent: false
    }
  },
  methods: {
    formatDateForLocale,
    formatHHmm,
    async show () {
      try {
        const response = await getSmsData({ ids: this.leadIds })
        this.$set(this.values, 'template', response?.data?.template)
        this.leads = response?.data?.leads
        this.$refs.modal.show()
      } catch (error) {
        console.error(error)
        errorModal('De sms-data kon niet worden ingeladen.')
      }
    },
    async send (values) {
      try {
        this.isLoading = true
        values.ids = this.leads.map(lead => lead.id)
        const response = await sendBulkSms(values)
        this.isLoading = false
        this.isSent = true
        return response
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het versturen van de sms-en.')
      }
    }
  }
}
</script>

<style scoped>
th, td {
  @apply tw-px-4 tw-py-2 tw-text-left tw-text-sm;
}
tr {
  @apply tw-border-b-0.5 tw-divide-x-0.5 even:tw-bg-success even:tw-bg-opacity-10;
}
</style>
